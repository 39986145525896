import type { ServiceHookViewModel } from "~/src/models/ServiceHook/ServiceHook.viewModel";

export type MetadataViewModel = { key: string; value: string }[];

// Returns {key: 'keyValue', value: 'Foo'}[]
export const convertMetadataObjToArrayClient = (
  obj: Record<string, string>
) => {
  return Object.entries(obj).map(([key, value]) => {
    return {
      key,
      value,
    };
  });
};

// Returns as array of objects
export const convertMetadataArrayToObjArrayClient = (
  metadata: MetadataViewModel
) => {
  return metadata.map((x) => ({
    [x.key]: x.value,
  }));
};

// Returns as normal object
export const convertMetadataArrayToObjClient = (
  metadata: MetadataViewModel
) => {
  return metadata.reduce(
    (prev, curr) => {
      prev[curr.key] = curr.value;
      return prev;
    },
    {} as Record<string, string>
  );
};

// SERVICE HOOK
// Map from array to object
export const mapHeaders = (
  headers: ServiceHookViewModel["options"]["headers"]
) => {
  return headers.reduce(
    (acc, header) => {
      acc[header.key] = header.value;
      return acc;
    },
    {} as Record<string, string>
  );
};

// For service hook test
export const mapJSONPatch = (
  headers: ServiceHookViewModel["options"]["headers"]
) => {
  return headers.reduce(
    (acc, header) => {
      acc[header.key] = [header.value];
      return acc;
    },
    {} as Record<string, string[]>
  );
};
